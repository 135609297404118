interface BoxHeaderTitleProps {
  title: string;
}

const BoxHeaderTitle = ({ title }: BoxHeaderTitleProps) => {
  return (
    <div className="flex w-full">
      <div className="bg-[#283840] px-2 py-0 h-6 rounded-[6px_0px_6px_0px]">
        <span className="text-white font-semibold text-base tracking-[-0.35px]">
          {title}
        </span>
      </div>
      <div className="h-1 flex-1 bg-[#283840] rounded-[0px_6px_0px_0px]" />
    </div>
  );
};

export default BoxHeaderTitle;

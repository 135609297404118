import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { Loader } from '@/modules/theme/components/Loader';

interface InfiniteScrollProps {
  loadMore: () => void;
  hasMore: boolean;
  isLoading: boolean;
  children: React.ReactNode;
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  loadMore,
  hasMore,
  isLoading,
  children,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  useEffect(() => {
    if (inView && hasMore && !isLoading) {
      loadMore();
    }
  }, [inView, hasMore, loadMore, isLoading]);

  return (
    <div>
      {children}
      {hasMore && !isLoading && <div ref={ref} style={{ height: '1px' }}></div>}
      {isLoading && (
        <div className="flex items-center mx-auto w-10 my-4">
          <Loader size={20} />
        </div>
      )}
    </div>
  );
};

export default InfiniteScroll;

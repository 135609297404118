import * as sup from 'superstruct';

import { NegotiationState } from '@/modules/negotiation/types/negotiationTypes';

import { negotiationSupplierStruct } from './negotiationResourceStructV2';

export enum NegotiationEventType {
  CHANGE_STATE = 'CHANGE_STATE',
  SUPPLIER_NEW_STATE = 'SUPPLIER_NEW_STATE',
  SUPPLIER_ANSWERED = 'SUPPLIER_ANSWERED',
  SUPPLIER_ANSWER_UPDATED = 'SUPPLIER_ANSWER_UPDATED',
}

const ResponseDetailsSchema = sup.object({
  discount: sup.optional(sup.number()),
  lockedPrice: sup.optional(sup.boolean()),
  acceptedExtraPaymentDays: sup.optional(sup.boolean()),
  acceptedStandardAgreement: sup.optional(sup.boolean()),
  acceptedCoc: sup.optional(sup.boolean()),
  acceptedProlong: sup.optional(sup.boolean()),
  answeredQuestionnaire: sup.optional(sup.boolean()),
  answeredIso: sup.optional(sup.boolean()),
});

export const NegotiationEventStruct = sup.object({
  event: sup.union([
    sup.object({
      id: sup.string(),
      negotiationId: sup.string(),
      eventType: sup.literal(NegotiationEventType.CHANGE_STATE),
      timestamp: sup.string(),
      payload: sup.object({
        negotiationState: sup.enums(Object.values(NegotiationState)),
      }),
    }),
    sup.object({
      id: sup.string(),
      negotiationId: sup.string(),
      eventType: sup.literal(NegotiationEventType.SUPPLIER_NEW_STATE),
      timestamp: sup.string(),
      payload: sup.object({
        negotiationSupplierId: sup.string(),
      }),
    }),
    sup.object({
      id: sup.string(),
      negotiationId: sup.string(),
      eventType: sup.literal(NegotiationEventType.SUPPLIER_ANSWERED),
      timestamp: sup.string(),
      payload: sup.object({
        negotiationSupplierId: sup.string(),
        nsResponseId: sup.string(),
        responseDetails: sup.optional(ResponseDetailsSchema),
      }),
    }),
    sup.object({
      id: sup.string(),
      negotiationId: sup.string(),
      eventType: sup.literal(NegotiationEventType.SUPPLIER_ANSWER_UPDATED),
      timestamp: sup.string(),
      payload: sup.object({
        negotiationSupplierId: sup.string(),
        nsResponseId: sup.string(),
        previousResponseDetails: sup.optional(ResponseDetailsSchema),
        responseDetails: sup.optional(ResponseDetailsSchema),
      }),
    }),
  ]),
  negotiationSupplier: sup.optional(negotiationSupplierStruct),
});

import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { NegotiationLayout } from '@/layouts/NegotiationLayout/NegotiationLayout';
import { useGetNegotiationQueryV2 } from '@/modules/negotiation/hooks/useGetNegotiationV2Query';
import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { Box } from '@/modules/theme/components/Box';
import {
  HeaderButton,
  HeaderButtonLink,
} from '@/modules/theme/components/HeaderButton';
import { Tabs } from '@/modules/theme/components/Tabs/Tabs';

import { useNegotiationTabItems } from './hooks/use-negotiation-tab-items';

export const ANALYSIS_PAGE_ACTIVE_TAB = 'analysisPageActiveTab';

export const NegotiationTabLayout = () => {
  const { t } = useTranslation('pages/AnalysisAndOfferPage');
  const navigate = useNavigate();
  const tabs = useNegotiationTabItems();
  const { negotiationId } =
    onboardedRoutes.useGetRouteParams('activeNegotiation');

  const { data: negotiation, isPending: isNegotiationPending } =
    useGetNegotiationQueryV2({
      negotiationId,
    });

  return (
    <NegotiationLayout
      negotiationName={negotiation?.attributes.name}
      negotiationState={negotiation?.attributes.state as NegotiationStateV2}
      isLoading={isNegotiationPending}
      buttonLink={
        <div className="flex ml-auto space-x-2">
          <HeaderButtonLink
            to={onboardedRoutes.buildUrl('cpoLetter', {
              params: { negotiationId },
            })}
          >
            {t('Invitation letter')}
          </HeaderButtonLink>

          <HeaderButton onClick={() => navigate(-1)}>{t('Back')}</HeaderButton>
        </div>
      }
    >
      <Box mt={7} mb={7}>
        <Tabs tabs={tabs} variant="small" />
      </Box>
      <Outlet />
    </NegotiationLayout>
  );
};

import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { PageHeader } from '@/modules/theme/components/PageHeader/PageHeader';

import { AuthenticatedLayout } from '../AuthenticatedLayout/AuthenticatedLayout';

type NegotiationLayoutProps = {
  children: ReactNode;
  negotiationName?: string;
  negotiationState?: NegotiationStateV2;
  headerData?: string;
  isLoading?: boolean;
  buttonLink?: ReactNode;
};

export const NegotiationLayout = ({
  children,
  isLoading,
  negotiationName,
  negotiationState,
  headerData,
  buttonLink,
}: NegotiationLayoutProps) => {
  const { t } = useTranslation('layouts/NegotiationLayout');

  const statusLabel = (negotiationState: NegotiationStateV2 | undefined) => {
    switch (negotiationState) {
      case NegotiationStateV2.SUGGESTED:
      case NegotiationStateV2.IN_SETUP:
        return t('In setup');
      case NegotiationStateV2.TO_BE_APPROVED:
        return t('To be approved');
      case NegotiationStateV2.PLANNED:
        return t('Planned');
      case NegotiationStateV2.IN_PROGRESS:
        return t('Active');
      case NegotiationStateV2.COMPLETED:
        return t('Completed');
      default:
        return '';
    }
  };

  const pageHeaderVariant =
    negotiationState === NegotiationStateV2.COMPLETED ? 'grey' : 'color';

  const pageHeaderText = `${negotiationName || ''} (${statusLabel(
    negotiationState
  )})`;

  return (
    <AuthenticatedLayout>
      <PageHeader
        text={pageHeaderText}
        isLoading={isLoading}
        variant={pageHeaderVariant}
        buttonLink={buttonLink}
      >
        {headerData}
      </PageHeader>
      <main>{children}</main>
    </AuthenticatedLayout>
  );
};

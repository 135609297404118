import { getValue } from 'firebase/remote-config';
import { useState, useEffect, useMemo, useContext } from 'react';

import { authContext } from '@/modules/auth/contexts/AuthContext';

import { firebaseFetchRemoteConfig, remoteConfig } from '..';

export interface FeatureFlagData {
  key: string;
  enabled: boolean;
  activeFor?: string[];
  disabledFor?: string[];
  variables?: Record<string, string>;
}

const useFeatureFlag = (
  flag: string
):
  | {
      enabled: boolean;
      featureFlag: FeatureFlagData;
    }
  | undefined => {
  const [value, setValue] = useState<FeatureFlagData>();
  const { customer } = useContext(authContext);

  const key = useMemo(() => `feature_${flag}`, [flag]);

  useEffect(() => {
    const fetchValue = async () => {
      await firebaseFetchRemoteConfig();
      // Get the value for the key
      const fetchedValue = getValue(remoteConfig, key).asString();
      const jsonValue = JSON.parse(fetchedValue || '{}') as FeatureFlagData;
      setValue(jsonValue);
    };

    void fetchValue();
  }, [key]);

  if (!value) {
    return undefined;
  }

  if (!value.enabled) {
    return { enabled: false, featureFlag: value };
  }

  if (
    customer?.id &&
    value.enabled &&
    value.disabledFor &&
    value.disabledFor.length > 0 &&
    value.disabledFor.includes(customer.id)
  ) {
    return { enabled: false, featureFlag: value };
  }

  if (value.enabled && value.activeFor && value.activeFor.length > 0) {
    if (!customer?.id || !value.activeFor?.includes(customer.id)) {
      return { enabled: false, featureFlag: value };
    }
  }

  return { enabled: value.enabled, featureFlag: value };
};

export default useFeatureFlag;

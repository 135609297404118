import { createElement } from 'react';

import { Card, CardContent } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';

import BoxHeaderTitle from './box-header-title';

interface Metric {
  value: string;
  label: string;
}

interface PriceMetricsBoxProps {
  title: string;
  mainValue: string;
  mainLabel: string;
  metrics: Metric[];
  icon: React.ElementType;
  active: boolean;
  className?: string;
}

const PriceMetricsBox = ({
  title,
  mainValue,
  mainLabel,
  metrics,
  icon,
  active,
  className,
}: PriceMetricsBoxProps) => {
  const textColor = active ? 'text-[#dc840a]' : 'text-[#aaaaaa]';
  const metricsColor = active ? 'text-[#4ba5b4]' : 'text-[#aaaaaa]';
  const labelColor = active ? 'text-[#234b67]' : 'text-[#aaaaaa]';

  return (
    <Card className={`relative min-h-[380px] ${className ?? ''}`}>
      <BoxHeaderTitle title={title} />
      <CardContent className="flex flex-col gap-10 pt-10">
        <div className="flex flex-col items-center gap-2">
          <span
            className={`text-[40px] font-semibold tracking-[-0.88px] ${textColor}`}
          >
            {mainValue}
          </span>
          <span
            className={`text-base font-semibold tracking-[-0.35px] ${textColor}`}
          >
            {mainLabel}
          </span>
        </div>

        <div className="flex justify-center items-start gap-2">
          {metrics.map((metric, index) => (
            <>
              <div
                key={index}
                className="flex flex-col w-[95px] items-center gap-4"
              >
                <span
                  className={`text-2xl font-semibold tracking-[-0.53px] ${metricsColor}`}
                >
                  {metric.value}
                </span>
                <span
                  className={`text-[12px] leading-normal font-semibold tracking-[-0.22px] text-center whitespace-pre-line ${labelColor}`}
                >
                  {metric.label}
                </span>
              </div>
              {index < metrics.length - 1 && (
                <Separator
                  orientation="vertical"
                  className="h-[100px] bg-[#d9d9d9]"
                />
              )}
            </>
          ))}
        </div>

        <div className="absolute bottom-[24px] right-[24px] opacity-16">
          {createElement(icon, { size: 83 })}
        </div>
      </CardContent>
    </Card>
  );
};

export default PriceMetricsBox;

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApiV2 } from '@/modules/api/hooks/useJsonApiV2';
import { queryKeys } from '@/modules/api/queryKeys';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

import { getNegotiationEventsQuery } from '../api/queries/get-negotiation-events-query';
import { NegotiationEventType } from '../api/resources/negotiation/negotiation-events-resource-struct';
import { GetNegotiationsQueryOptions } from '../api/resources/negotiation/negotiationResourceConsts';

export type GetNegotiationEventsQueryParams = {
  limit: number;
  page: number;
  negotiationId: string;
  eventTypes: NegotiationEventType[];
};

export const useGetNegotiationEventsQuery = (
  params: GetNegotiationEventsQueryParams,
  options?: GetNegotiationsQueryOptions
) => {
  const jsonApi = useJsonApiV2();
  const { t } = useTranslation('apiMessages/usegetNegotiationEventsQuery');
  return useQuery({
    queryKey: [
      queryKeys.negotiations.getNegotiationEvents,
      params.page,
      params.limit,
      params.negotiationId,
      params.eventTypes,
    ],
    queryFn: getNegotiationEventsQuery(jsonApi, params),
    ...options,
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to view negotiation"),
        },
      },
    }),
  });
};

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { StartedNegotiationSuppliersList } from '@/modules/negotiation/components/StartedNegotiationSuppliersList/StartedNegotiationSuppliersList';
import { defaultPrefixedSuppliersQueryParams } from '@/modules/negotiation/helpers/defaultPrefixedSuppliersQueryParams';
import { useGetNegotiationQueryV2 } from '@/modules/negotiation/hooks/useGetNegotiationV2Query';
import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { notify } from '@/modules/notify/notify';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { Box } from '@/modules/theme/components/Box';
import { PageMeta } from '@/modules/theme/components/PageMeta';

export const NegotiationSuppliersPage = () => {
  const { t } = useTranslation('pages/NegotiationPage');
  const { negotiationId } =
    onboardedRoutes.useGetRouteParams('activeNegotiation');
  const { queryParams, queryParamsError } =
    onboardedRoutes.useGetQueryParams('activeNegotiation');

  const { data: negotiation, isPending: isNegotiationPending } =
    useGetNegotiationQueryV2(
      {
        negotiationId,
      },
      {
        refetchOnWindowFocus: true,
        staleTime: 5000,
        refetchInterval: 60_000,
      }
    );

  useEffect(() => {
    if (queryParamsError) {
      notify.alert.error(t('Invalid pagination state'));
    }
  }, [queryParamsError, t]);

  if (queryParamsError) {
    return (
      <Navigate
        to={onboardedRoutes.buildUrl('activeNegotiation', {
          params: { negotiationId },
          query: defaultPrefixedSuppliersQueryParams,
        })}
      />
    );
  }

  return (
    <>
      <PageMeta
        title={negotiation?.attributes.name || t('Negotiation suppliers')}
        description={negotiation?.attributes.name || t('Negotiation suppliers')}
      />
      <Box paddings={[0, 8, 7]}>
        {negotiation && (
          <StartedNegotiationSuppliersList
            negotiation={negotiation}
            queryParams={queryParams}
            isLoading={isNegotiationPending}
            isNegotiationActive={
              (negotiation?.attributes.state as NegotiationStateV2) ===
                NegotiationStateV2.IN_PROGRESS ||
              (negotiation?.attributes.state as NegotiationStateV2) ===
                NegotiationStateV2.COMPLETED
            }
          />
        )}
      </Box>
    </>
  );
};

import { Pie, PieChart } from 'recharts';

import { ChartConfig, ChartContainer } from '@/components/ui/chart';

interface HalfCirclePieProps {
  label: string;
  chartData: {
    label: string;
    color: string;
    value: number;
  }[];
}

export const HalfCirclePie = ({ label, chartData }: HalfCirclePieProps) => {
  return (
    <ChartContainer
      config={
        chartData.reduce(
          (acc, item) => ({
            ...acc,
            [item.label]: {
              label: item.label,
              color: item.color,
            },
          }),
          {
            [label]: {
              label,
            },
          }
        ) as ChartConfig
      }
      className="w-[100%] h-[100%]"
    >
      <PieChart>
        <Pie
          data={chartData.map((item) => ({
            label: item.label,
            [label]: item.value,
            fill: item.color,
          }))}
          dataKey={label}
          nameKey="label"
          startAngle={180}
          endAngle={0}
          cx="50%"
          cy="100%"
          innerRadius="160%"
          outerRadius="180%"
          fill="#8884d8"
          paddingAngle={2}
          cornerRadius={16}
        />
      </PieChart>
    </ChartContainer>
  );
};

import { FunctionComponent, SVGAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import useFeatureFlag from '@/modules/firebase/hooks/use-feature-flag';
import { analysisRoutes } from '@/modules/router/analysisRoutes';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { defaultSuppliersQueryParams } from '@/modules/suppliers/utils/consts';

import { ReactComponent as AccountIcon } from './icons/account.svg';
import { ReactComponent as ListIcon } from './icons/list.svg';
import { ReactComponent as OverviewIcon } from './icons/overview.svg';
import { ReactComponent as SpendAnalysisIcon } from './icons/spendAnalysis.svg';
import { ReactComponent as SuppliersIcon } from './icons/suppliers.svg';
import { ReactComponent as SupportIcon } from './icons/support.svg';

type MenuItem = {
  url: string;
  text: string;
  svg: {
    src: FunctionComponent<SVGAttributes<SVGElement>>;
    title: string;
    description: string;
  };
};

export const useMenuItems = () => {
  const { t } = useTranslation('layouts/AuthenticatedLayout');
  const negotiationMetricsFeature = useFeatureFlag(
    'customer_negotiation_metrics'
  );

  const commonMenuItems: MenuItem[] = [
    {
      url: onboardedRoutes.buildUrl('dashboard'),
      text: t('Dashboard'),
      svg: {
        src: OverviewIcon,
        title: t('Dashboard'),
        description: t('Go to dashboard'),
      },
    },
    ...(negotiationMetricsFeature?.enabled
      ? [
          {
            url: onboardedRoutes.buildUrl('negotiations'),
            text: t('Negotiations'),
            svg: {
              src: ListIcon,
              title: t('Negotiations'),
              description: t('See Negotiations list'),
            },
          },
        ]
      : []),
    {
      url: analysisRoutes.buildUrl('analysisAndOffer'),
      text: t('Spend analysis'),
      svg: {
        src: SpendAnalysisIcon,
        title: t('Spend analysis'),
        description: t('Go to spend analysis'),
      },
    },
    {
      url: onboardedRoutes.buildUrl('support'),
      text: t('Support'),
      svg: {
        src: SupportIcon,
        title: t('Support'),
        description: t('Go to the support'),
      },
    },
    {
      url: onboardedRoutes.buildUrl('suppliers', {
        query: defaultSuppliersQueryParams,
      }),
      text: t('Suppliers'),
      svg: {
        src: SuppliersIcon,
        title: t('Suppliers'),
        description: t('Go to the suppliers'),
      },
    },
    {
      url: onboardedRoutes.buildUrl('profile'),
      text: t('Account'),
      svg: {
        src: AccountIcon,
        title: t('Account'),
        description: t('Go to the account'),
      },
    },
  ];

  return commonMenuItems;
};

import { HalfCirclePie } from '@/components/charts/half-circle-pie';
import { Card, CardContent } from '@/components/ui/card';
import { financialCompactFormatter } from '@/utils/financialCompactFormatter';

import BoxHeaderTitle from './box-header-title';

interface CompletedBoxProps {
  title: string;
  isMonetary?: boolean;
  graphInfo?: {
    label: string;
    value: string;
    color: string;
  };
  metrics?: {
    label: string;
    value: number;
    color: string;
    percentage: number;
  }[];
  icon?: JSX.Element;
  className?: string;
}

const CompletedBox = ({
  title,
  isMonetary = false,
  metrics,
  graphInfo,
  icon,
  className,
}: CompletedBoxProps) => {
  if (!metrics || !graphInfo) return null;

  const emptyMetrics = metrics.every((metric) => metric.value === 0);

  return (
    <Card className={`bg-white ${className ?? ''}`}>
      <BoxHeaderTitle title={title} />

      <CardContent className="relative p-0 pb-6">
        <div className="relative w-[100%] max-w-[398px] mx-auto px-6">
          <HalfCirclePie
            label="Label"
            chartData={
              emptyMetrics
                ? [
                    {
                      value: 1,
                      color: '#dadada',
                      label: 'No data',
                    },
                  ]
                : metrics
            }
          />

          <div className="absolute bottom-0 left-0 w-full text-center">
            <p
              className={`font-semibold text-[${graphInfo.color}] text-base tracking-[-0.35px]`}
            >
              {graphInfo.label}
            </p>
            <p
              className={`font-semibold text-[${graphInfo.color}] text-[40px] tracking-[-0.88px] leading-10`}
            >
              {graphInfo.value}
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-4 px-10 my-6">
          {metrics.map((metric) => (
            <div className="flex flex-col gap-2" key={metric.label}>
              <div className="flex items-center gap-4">
                <div
                  className={`w-3.5 h-3.5 bg-[${metric.color}] rounded-[7px]`}
                />
                <span
                  className={`font-semibold text-base text-[${metric.color}] tracking-[-0.35px]`}
                >
                  {metric.label}
                </span>
              </div>
              <span
                className={`font-semibold text-2xl text-[${metric.color}] tracking-[-0.53px]`}
              >
                {isMonetary
                  ? financialCompactFormatter.formatFull(metric.value)
                  : metric.value}{' '}
                (
                {financialCompactFormatter.formatNumberWithDecimals(
                  metric.percentage || 0
                )}
                %)
              </span>
            </div>
          ))}
        </div>
        <div className="absolute bottom-10 right-8 w-[101px] h-[101px] text-[#dc840a]">
          {icon}
        </div>
      </CardContent>
    </Card>
  );
};

export default CompletedBox;

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { ActiveNegotiationMetricsType } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Paragraph } from '@/modules/theme/components/Typography';
import { orange } from '@/modules/theme/utils/colors';
import { formatAmount } from '@/utils/stringUtils';

import { useGetCustomerMetricsQuery } from '../../utils/useGetCustomerMetricsQuery';

import { CustomerMetric } from './CustomerMetric/CustomerMetric';
import {
  CustomerMetricsSectionStyled,
  NumberStyled,
  ParagraphWithTopBorderStyled,
  SavingsTileStyled,
} from './CustomerMetricsPanel.styled';
import { MetricSkeletonLoader } from './MetricSkeletonLoader';

type CustomerMetricsPanelProps = {
  metrics?: ActiveNegotiationMetricsType;
  isLoading: boolean;
};

export const CustomerMetricsPanel = ({
  metrics,
  isLoading,
}: CustomerMetricsPanelProps) => {
  const { t } = useTranslation('pages/DashboardPage');
  const {
    customer: { currency },
  } = useSessionContext();

  const { data: customerMetrics, isPending: isMetricsPending } =
    useGetCustomerMetricsQuery();

  const negotiatedSpend = useMemo(() => {
    let addressedSpend;
    if (metrics) {
      addressedSpend = (
        metrics.attributes.supplierStatesSpend.OPENED_EMAIL +
        metrics.attributes.supplierStatesSpend.VISITED +
        metrics.attributes.supplierStatesSpend.NEGOTIATION_STARTED +
        metrics.attributes.supplierStatesSpend.NEGOTIATION_COMPLETED
      ).toFixed(2);
    }

    return Number(addressedSpend);
  }, [metrics]);
  const negotiatedSavings = Number(metrics?.attributes?.improvedPricesValue);
  const suppliersCount = Number(customerMetrics?.suppliersCount);
  const contactsCount = Number(customerMetrics?.contactsCount);

  const suppliersPercentage = suppliersCount
    ? Math.round((contactsCount / suppliersCount) * 100)
    : 0;

  return (
    <CustomerMetricsSectionStyled>
      <SavingsTileStyled label={t('Savings')}>
        <FlexContainer wrap="wrap" columnGap={14.5}>
          <div>
            {isLoading || isMetricsPending ? (
              <MetricSkeletonLoader />
            ) : (
              <>
                <Paragraph variant="contentBold">
                  {t('Negotiated spend ({{currency}})', {
                    currency,
                  })}
                </Paragraph>
                <NumberStyled color={orange[800]}>
                  {formatAmount(negotiatedSpend)}
                </NumberStyled>
              </>
            )}
          </div>

          <div>
            {isLoading || isMetricsPending ? (
              <MetricSkeletonLoader />
            ) : (
              <>
                <Paragraph variant="contentBold">
                  {t('Negotiated savings ({{currency}})', {
                    currency,
                  })}
                </Paragraph>
                <NumberStyled color={orange[800]}>
                  {formatAmount(negotiatedSavings)}
                </NumberStyled>
              </>
            )}
          </div>
        </FlexContainer>
      </SavingsTileStyled>
      <CustomerMetric label={t('Suppliers & emails')}>
        <div>
          {isLoading ? (
            <MetricSkeletonLoader />
          ) : (
            <>
              <Paragraph variant="contentBold">{t('Email contacts')}</Paragraph>
              <NumberStyled color={orange[800]}>{contactsCount}</NumberStyled>
              <ParagraphWithTopBorderStyled>
                {t(
                  '{{percentage}}% of potential {{potentialNumber}} suppliers',
                  {
                    percentage: suppliersPercentage,
                    potentialNumber: formatAmount(suppliersCount),
                  }
                )}
              </ParagraphWithTopBorderStyled>
            </>
          )}
        </div>
      </CustomerMetric>
    </CustomerMetricsSectionStyled>
  );
};

import { ActiveNegotiationMetricsType } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { NegotiationSupplierStateV2 } from '@/modules/negotiation/types/negotiationTypes';

export const calculateNegotiationsMetrics = (
  metrics: ActiveNegotiationMetricsType[]
): ActiveNegotiationMetricsType => {
  return {
    id: 'sum',
    type: 'negotiationMetrics',
    attributes: {
      spend: {
        total: metrics.reduce(
          (acc, metric) => acc + metric.attributes.spend.total,
          0
        ),
        active: metrics.reduce(
          (acc, metric) => acc + metric.attributes.spend.active,
          0
        ),
        completed: metrics.reduce(
          (acc, metric) => acc + metric.attributes.spend.completed,
          0
        ),
        accepted: metrics.reduce(
          (acc, metric) => acc + metric.attributes.spend.accepted,
          0
        ),
        discount: metrics.reduce(
          (acc, metric) => acc + metric.attributes.spend.discount,
          0
        ),
        lockedPrice: metrics.reduce(
          (acc, metric) => acc + metric.attributes.spend.lockedPrice,
          0
        ),
        acceptedExtraPaymentDays: metrics.reduce(
          (acc, metric) =>
            acc + metric.attributes.spend.acceptedExtraPaymentDays,
          0
        ),
        acceptedStandardAgreement: metrics.reduce(
          (acc, metric) =>
            acc + metric.attributes.spend.acceptedStandardAgreement,
          0
        ),
        acceptedCoc: metrics.reduce(
          (acc, metric) => acc + metric.attributes.spend.acceptedCoc,
          0
        ),
        acceptedProlong: metrics.reduce(
          (acc, metric) => acc + metric.attributes.spend.acceptedProlong,
          0
        ),
        answeredQuestionnaire: metrics.reduce(
          (acc, metric) => acc + metric.attributes.spend.answeredQuestionnaire,
          0
        ),
        answeredIso: metrics.reduce(
          (acc, metric) => acc + metric.attributes.spend.answeredIso,
          0
        ),
        supplierRegistration: metrics.reduce(
          (acc, metric) => acc + metric.attributes.spend.supplierRegistration,
          0
        ),
      },
      extraPaymentDaysValue: metrics.reduce(
        (acc, metric) => acc + metric.attributes.extraPaymentDaysValue,
        0
      ),
      workingCapitalImprovement: metrics.reduce(
        (acc, metric) => acc + metric.attributes.workingCapitalImprovement,
        0
      ),
      counts: {
        accepted: metrics.reduce(
          (acc, metric) => acc + metric.attributes.counts.accepted,
          0
        ),
        acceptedProlong: metrics.reduce(
          (acc, metric) => acc + metric.attributes.counts.acceptedProlong,
          0
        ),
        responses: metrics.reduce(
          (acc, metric) => acc + metric.attributes.counts.responses,
          0
        ),
        discount: metrics.reduce(
          (acc, metric) => acc + metric.attributes.counts.discount,
          0
        ),
        lockedPrice: metrics.reduce(
          (acc, metric) => acc + metric.attributes.counts.lockedPrice,
          0
        ),
        acceptedExtraPaymentDays: metrics.reduce(
          (acc, metric) =>
            acc + metric.attributes.counts.acceptedExtraPaymentDays,
          0
        ),
        acceptedStandardAgreement: metrics.reduce(
          (acc, metric) =>
            acc + metric.attributes.counts.acceptedStandardAgreement,
          0
        ),
        acceptedCoc: metrics.reduce(
          (acc, metric) => acc + metric.attributes.counts.acceptedCoc,
          0
        ),
        answeredQuestionnaire: metrics.reduce(
          (acc, metric) => acc + metric.attributes.counts.answeredQuestionnaire,
          0
        ),
        answeredIso: metrics.reduce(
          (acc, metric) => acc + metric.attributes.counts.answeredIso,
          0
        ),
        noResponseReason: metrics.reduce(
          (acc, metric) => acc + metric.attributes.counts.noResponseReason,
          0
        ),
        supplierRegistration: metrics.reduce(
          (acc, metric) => acc + metric.attributes.counts.supplierRegistration,
          0
        ),
      },
      averageDiscount:
        metrics.reduce(
          (acc, metric) => acc + (metric.attributes.averageDiscount || 0),
          0
        ) / metrics.length,
      improvedPricesValue: metrics.reduce(
        (acc, metric) => acc + (metric.attributes.improvedPricesValue || 0),
        0
      ),
      lockedPricesValue: metrics.reduce(
        (acc, metric) => acc + (metric.attributes.lockedPricesValue || 0),
        0
      ),
      supplierStatesCount: metrics.reduce(
        (acc, metric) => {
          const supplierStatesCount =
            metric.attributes.supplierStatesCount ||
            ({} as Record<NegotiationSupplierStateV2, number>);
          Object.entries(supplierStatesCount).forEach(([key, value]) => {
            acc[key as NegotiationSupplierStateV2] =
              (acc[key as NegotiationSupplierStateV2] || 0) + value;
          });
          return acc;
        },
        {} as Record<NegotiationSupplierStateV2, number>
      ),
      supplierStatesSpend: metrics.reduce(
        (acc, metric) => {
          const supplierStatesSpend =
            metric.attributes.supplierStatesSpend ||
            ({} as Record<NegotiationSupplierStateV2, number>);
          Object.entries(supplierStatesSpend).forEach(([key, value]) => {
            acc[key as NegotiationSupplierStateV2] =
              (acc[key as NegotiationSupplierStateV2] || 0) + value;
          });
          return acc;
        },
        {} as Record<NegotiationSupplierStateV2, number>
      ),
    },
  };
};

import * as sup from 'superstruct';

import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';

import { GetNegotiationEventsQueryParams } from '../../hooks/use-get-negotiation-events-query';
import { NegotiationEventStruct } from '../resources/negotiation/negotiation-events-resource-struct';

export const getNegotiationEventsQuery = (
  jsonApi: JsonApiV2Client,
  queryParams: GetNegotiationEventsQueryParams
) => {
  return async () => {
    const result = await jsonApi.get({
      path: '/v2/negotiation-events',
      queryParams: {
        ...queryParams,
        eventTypes: queryParams.eventTypes?.join(','),
      },
      responseStruct: getNegotiationEventListResponseType,
    });
    return getNegotiationEventListResponseType.mask(result);
  };
};

export type GetNegotiationEventListResponseType = sup.Infer<
  typeof getNegotiationEventListResponseType
>;

export type NegotiationEvent = sup.Infer<typeof NegotiationEventStruct>;

const getNegotiationEventListResponseType = sup.object({
  data: sup.object({
    items: sup.array(NegotiationEventStruct),
    pagination: sup.object({
      page: sup.number(),
      total: sup.number(),
    }),
  }),
});

import { useEffect } from 'react';

import { ReactComponent as ResultsIcon } from '@/assets/icons/results.svg';
import { Card, CardContent } from '@/components/ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

import BoxHeaderTitle from './box-header-title';

interface Metric {
  label: string;
  value: string;
}

export interface ResultTabs {
  label: string;
  value: string;
  metrics: Metric[];
}

interface PriceNegotiationResultsProps {
  setActiveTab: (tab: string) => void;
  activeTab?: string;
  resultTabs?: ResultTabs[];
  className?: string;
}

const PriceNegotiationResults = ({
  activeTab,
  setActiveTab,
  resultTabs,
  className,
}: PriceNegotiationResultsProps) => {
  useEffect(() => {
    if (!activeTab && resultTabs?.length) {
      setActiveTab(resultTabs?.[0].value);
    }
  }, [activeTab, resultTabs, setActiveTab]);

  return (
    <Card
      className={`flex flex-col w-full rounded-md overflow-hidden ${
        className ?? ''
      }`}
    >
      <BoxHeaderTitle title="Results so far" />

      <CardContent className="relative h-full flex-1">
        <Tabs value={activeTab} onValueChange={setActiveTab}>
          {resultTabs && resultTabs.length > 1 && resultTabs.length <= 3 && (
            <TabsList className="mt-6 bg-[#234b67] text-white rounded-md h-auto p-0.5">
              {resultTabs.map((tab) => (
                <TabsTrigger
                  key={tab.value}
                  value={tab.value}
                  className="tab-active:bg-white tab-active:text-[#234b67] py-2"
                >
                  <span className="font-semibold text-xs">{tab.label}</span>
                </TabsTrigger>
              ))}
            </TabsList>
          )}

          {resultTabs && resultTabs.length > 3 && (
            <Select value={activeTab} onValueChange={setActiveTab}>
              <SelectTrigger className="mt-6 w-full">
                <SelectValue placeholder="Theme" />
              </SelectTrigger>
              <SelectContent>
                {resultTabs.map((tab) => (
                  <SelectItem key={tab.value} value={tab.value}>
                    {tab.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
          {resultTabs?.map((tab) => (
            <TabsContent
              key={tab.value}
              value={tab.value}
              className="mt-6 space-y-6"
            >
              {tab.metrics.map((metric, idx) => (
                <div key={idx} className="space-y-2">
                  <div className="font-semibold text-[#234b67] text-base">
                    {metric.label}
                  </div>
                  <div className="font-semibold text-[#dc840a] text-[40px] leading-[60px]">
                    {metric.value}
                  </div>
                </div>
              ))}
            </TabsContent>
          ))}
        </Tabs>

        <div className="absolute bottom-10 right-8 w-[101px] h-[101px] text-[#dc840a]">
          <ResultsIcon />
        </div>
      </CardContent>
    </Card>
  );
};

export default PriceNegotiationResults;

import { createRouting, defineRoute, nestRoute } from './config/createRouting';
import { negotiationWithProgressSuppliersQueryStruct } from './utils/structs';

export const negotiationRoutes = createRouting({
  negotiation: defineRoute({
    path: '/negotiation/:negotiationId' as const,
  }),
});

export const negotiationNestedRoutes = createRouting({
  overview: nestRoute(
    negotiationRoutes.config.negotiation,
    defineRoute({
      path: 'overview' as const,
    })
  ),
  suppliers: nestRoute(
    negotiationRoutes.config.negotiation,
    defineRoute({
      path: 'suppliers' as const,
      queryStruct: negotiationWithProgressSuppliersQueryStruct,
    })
  ),
});

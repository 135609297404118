import { NegotiationResourceTypeV2 } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';

export const getNegotiationStateCounts = (
  negotiations?: NegotiationResourceTypeV2[]
): Record<NegotiationStateV2, number> | undefined => {
  if (!negotiations) {
    return undefined;
  }
  return negotiations
    .filter(
      (item) =>
        !(
          (item.attributes.state === NegotiationStateV2.DEMO ||
            item.attributes.state === NegotiationStateV2.SUGGESTED) &&
          (item.attributes.suggestedPeriod === undefined ||
            item.attributes.suggestedPeriod < 0)
        )
    )
    .reduce(
      (acc: Record<NegotiationStateV2, number>, negotiation) => {
        acc[negotiation.attributes.state as NegotiationStateV2] =
          (acc[negotiation.attributes.state as NegotiationStateV2] || 0) + 1;
        return acc;
      },
      {} as Record<NegotiationStateV2, number>
    );
};

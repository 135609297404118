import { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CoinsIcon } from '@/assets/icons/coins.svg';
import { ReactComponent as PeopleIcon } from '@/assets/icons/people-2.svg';
import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationEvent } from '@/modules/negotiation/api/queries/get-negotiation-events-query';
import { NegotiationEventType } from '@/modules/negotiation/api/resources/negotiation/negotiation-events-resource-struct';
import CompletedBox from '@/modules/negotiation/components/metrics/completed-spend-box';
import EventFeed from '@/modules/negotiation/components/metrics/event-feed';
import PriceNegotiationResults from '@/modules/negotiation/components/metrics/price-negotiation-results';
import { ProgressOfNegotiationSkeleton } from '@/modules/negotiation/components/ProgressOfNegotiation/components/ProgressOfNegotiationSkeleton';
import { useGetNegotiationEventsQuery } from '@/modules/negotiation/hooks/use-get-negotiation-events-query';
import { useGetNegotiationQueryV2 } from '@/modules/negotiation/hooks/useGetNegotiationV2Query';
import { NegotiationType } from '@/modules/negotiation/types/negotiationTypes';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { Box } from '@/modules/theme/components/Box';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { financialCompactFormatter } from '@/utils/financialCompactFormatter';

import { useNegotiationCompletedMetrics } from './hooks/use-negotiation-completed-metrics.hook';
import { useNegotiationEventFeed } from './hooks/use-negotiation-event-feed.hook';
import { useNegotiationResultsMetrics } from './hooks/use-negotiation-results-metrics.hook';

const ProgressOfNegotiation = lazy(
  () =>
    import(
      '@/modules/negotiation/components/ProgressOfNegotiation/ProgressOfNegotiation'
    )
);

export const NegotiationPage = () => {
  const { t } = useTranslation('pages/NegotiationPage');
  const {
    customer: { currency },
  } = useSessionContext();
  const { negotiationId } =
    onboardedRoutes.useGetRouteParams('activeNegotiation');

  const [activeTab, setActiveTab] = useState<string>();
  const [negotiationEvents, setNegotiationEvents] = useState<
    NegotiationEvent[]
  >([]);
  const [negotiationEventsPagination, setNegotiationEventsPagination] =
    useState<{
      page: number;
      total: number;
    }>({
      page: 1,
      total: 0,
    });
  const [fetchNegotiationEventsPage, setFetchNegotiationEventsPage] =
    useState<number>(1);

  const { data: negotiation } = useGetNegotiationQueryV2(
    {
      negotiationId,
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000,
      refetchInterval: 60_000,
    }
  );

  const { data: newNegotiationEvents, isPending: isNegotiationEventsPending } =
    useGetNegotiationEventsQuery({
      page: fetchNegotiationEventsPage,
      limit: 20,
      negotiationId,
      eventTypes: [
        NegotiationEventType.SUPPLIER_ANSWER_UPDATED,
        NegotiationEventType.SUPPLIER_ANSWERED,
      ],
    });

  useEffect(() => {
    if (newNegotiationEvents?.data) {
      const updatedEvents = [
        ...negotiationEvents,
        ...newNegotiationEvents.data.items.filter(
          (newEvent) =>
            !negotiationEvents.some(
              (existingEvent) => existingEvent.event.id === newEvent.event.id
            )
        ),
      ];
      setNegotiationEvents(updatedEvents);
      setNegotiationEventsPagination(newNegotiationEvents.data.pagination);
    }
  }, [newNegotiationEvents]);

  const negotiationEventsData = useNegotiationEventFeed(
    negotiationEvents,
    negotiation?.attributes.type as NegotiationType[]
  );

  const metrics = useMemo(() => {
    return negotiation?.attributes.negotiationMetrics;
  }, [negotiation]);

  const suppliersAddressedData = useMemo(() => {
    if (!negotiation) return undefined;
    const { negotiationMetrics } = negotiation.attributes;

    if (!negotiationMetrics) return undefined;

    const { supplierStatesCount, spend } = negotiationMetrics.attributes;

    return {
      addressedSpend: spend.active,
      addressedSuppliers:
        supplierStatesCount.OPENED_EMAIL +
        supplierStatesCount.VISITED +
        supplierStatesCount.NEGOTIATION_STARTED +
        supplierStatesCount.NEGOTIATION_COMPLETED,
      invitedSuppliers:
        supplierStatesCount.INVITED +
        supplierStatesCount.OPENED_EMAIL +
        supplierStatesCount.VISITED +
        supplierStatesCount.NEGOTIATION_STARTED +
        supplierStatesCount.NEGOTIATION_COMPLETED,
    };
  }, [negotiation]);

  const { completedSpendMetrics, completedSuppliersMetrics } =
    useNegotiationCompletedMetrics(metrics, activeTab);

  const resultsMetrics = useNegotiationResultsMetrics(
    currency,
    negotiation?.attributes.type as NegotiationType[],
    suppliersAddressedData?.addressedSpend || 0,
    suppliersAddressedData?.invitedSuppliers || 0,
    metrics
  );

  return (
    <>
      <PageMeta
        title={negotiation?.attributes.name || t('Negotiation details')}
        description={negotiation?.attributes.name || t('Negotiation details')}
      />
      <Box paddings={[0, 8, 7]}>
        <div className="flex flex-row flex-wrap gap-4 my-6">
          <PriceNegotiationResults
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            resultTabs={resultsMetrics}
            className="flex-[1_0_30%] min-w-[350px]"
          />
          <CompletedBox
            title="Answers - Spend"
            className="flex-[1_0_30%] min-w-[350px]"
            isMonetary
            metrics={completedSpendMetrics}
            icon={<CoinsIcon />}
            graphInfo={{
              label: 'Total answered spend',
              value: financialCompactFormatter.format(
                metrics?.attributes.supplierStatesSpend.NEGOTIATION_COMPLETED ||
                  0
              ),
              color: '#dc840a',
            }}
          />
          <CompletedBox
            title="Answers - Suppliers"
            className="flex-[1_0_30%] min-w-[350px]"
            metrics={completedSuppliersMetrics}
            icon={<PeopleIcon />}
            graphInfo={{
              label: 'Nº suppliers answers',
              value: Number(
                metrics?.attributes.supplierStatesCount.NEGOTIATION_COMPLETED ||
                  0
              ).toString(),
              color: '#4BA5B4',
            }}
          />
        </div>
        <Suspense
          fallback={
            <ProgressOfNegotiationSkeleton
              title={t('Progress of the negotiation so far')}
            />
          }
        >
          <ProgressOfNegotiation negotiationId={negotiationId} />
        </Suspense>
        {!negotiationEventsData.length &&
        suppliersAddressedData?.addressedSpend &&
        suppliersAddressedData?.addressedSpend > 0 ? null : (
          <EventFeed
            className="my-6"
            eventData={negotiationEventsData}
            isLoading={isNegotiationEventsPending}
            hasMore={
              (negotiationEvents &&
                negotiationEventsPagination.total > negotiationEvents.length) ??
              false
            }
            onLoadMore={() => {
              setFetchNegotiationEventsPage(fetchNegotiationEventsPage + 1);
            }}
          />
        )}
      </Box>
    </>
  );
};

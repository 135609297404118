import { HalfCirclePie } from '@/components/charts/half-circle-pie';
import { Card, CardContent } from '@/components/ui/card';
import { financialCompactFormatter } from '@/utils/financialCompactFormatter';

import BoxHeaderTitle from './box-header-title';

interface SpendItem {
  label: string;
  color: string;
  value?: number;
}

interface SpendBoxProps {
  title: string;
  label: string;
  items: SpendItem[];
  currency?: string;
  description?: string;
  className?: string;
}

const SpendBox = ({
  title,
  label,
  currency,
  items,
  description,
  className,
}: SpendBoxProps) => {
  return (
    <Card className={`bg-white ${className ?? ''}`}>
      <BoxHeaderTitle title={title} />

      <CardContent className="p-0 pb-6">
        <div className="relative w-[100%] max-w-[398px] mx-auto px-6">
          <HalfCirclePie
            label={label}
            chartData={items.map((item) => ({
              label: item.label,
              color: item.color,
              value: item.value || 0,
            }))}
          />

          <div className="absolute bottom-0 left-0 w-full text-center">
            {currency && (
              <p className="font-semibold text-[#dc840a] text-base tracking-[-0.35px]">
                {currency}
              </p>
            )}
            <p className="font-semibold text-[#dc840a] text-[40px] tracking-[-0.88px] leading-10">
              {financialCompactFormatter.format(
                items.reduce((acc, item) => acc + (item.value || 0), 0)
              )}
            </p>
            {description && (
              <p className="font-semibold text-[#dc840a] text-base tracking-[-0.35px]">
                {description}
              </p>
            )}
          </div>
        </div>

        <div className="mt-6 px-10 space-y-2">
          {items.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-between w-full"
            >
              <div className="flex items-center gap-4">
                <div
                  className="w-3.5 h-3.5 rounded-[7px]"
                  style={{ backgroundColor: item.color }}
                />
                <span className="font-semibold text-[#234b67] text-base tracking-[-0.35px]">
                  {item.label}
                </span>
              </div>
              {item.value && item.value !== 0 ? (
                <span className="font-semibold text-[#234b67] text-base tracking-[-0.35px]">
                  {financialCompactFormatter.formatFull(item.value)}{' '}
                  {currency ?? ''}
                </span>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default SpendBox;

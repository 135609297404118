import { useMemo } from 'react';

import { ActiveNegotiationMetricsType } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { ResultTabs } from '@/modules/negotiation/components/metrics/price-negotiation-results';
import { NegotiationType } from '@/modules/negotiation/types/negotiationTypes';
import { financialCompactFormatter } from '@/utils/financialCompactFormatter';

export const useNegotiationResultsMetrics = (
  currency: string,
  type: NegotiationType[],
  addressedSpend: number,
  invitedSuppliers: number,
  metrics?: ActiveNegotiationMetricsType
) => {
  return useMemo(() => {
    if (!metrics) {
      return undefined;
    }

    const resultMetrics = [] as ResultTabs[];

    type.forEach((type: NegotiationType) => {
      switch (type) {
        case NegotiationType.IMPROVED_PRICES:
          resultMetrics.push({
            label: 'Improved prices',
            value: NegotiationType.IMPROVED_PRICES,
            metrics: [
              {
                label: `Negotiated savings ${currency}`,
                value: financialCompactFormatter.formatFull(
                  metrics.attributes.improvedPricesValue
                ),
              },
              {
                label: 'Average discount',
                value: `${financialCompactFormatter.formatNumberWithDecimals(
                  metrics.attributes.averageDiscount || 0
                )}%`,
              },
              {
                label: 'Discount on addressed baseline',
                value: `${financialCompactFormatter.formatNumberWithDecimals(
                  (metrics.attributes.improvedPricesValue /
                    (addressedSpend || 1)) *
                    100
                )}%`,
              },
            ],
          });
          break;
        case NegotiationType.EXTRA_PAYMENT_DAYS:
          resultMetrics.push({
            label: 'Payment terms',
            value: NegotiationType.EXTRA_PAYMENT_DAYS,
            metrics: [
              {
                label: `Generated value ${currency}`,
                value: financialCompactFormatter.formatFull(
                  metrics.attributes.extraPaymentDaysValue
                ),
              },
              {
                label: 'Working capital improvement',
                value: financialCompactFormatter.formatFull(
                  metrics.attributes.workingCapitalImprovement
                ),
              },
              {
                label: 'Invited suppliers',
                value: invitedSuppliers.toString() ?? '',
              },
            ],
          });
          break;
        case NegotiationType.LOCKED_PRICES:
          resultMetrics.push({
            label: 'Locked prices',
            value: NegotiationType.LOCKED_PRICES,
            metrics: [
              {
                label: `Negotiated savings ${currency}`,
                value: financialCompactFormatter.formatFull(
                  metrics.attributes.lockedPricesValue
                ),
              },
              {
                label: 'Cost avoidance on addressed baseline',
                value: `${financialCompactFormatter.formatNumberWithDecimals(
                  (metrics.attributes.lockedPricesValue /
                    (addressedSpend || 1)) *
                    100
                )}%`,
              },
              {
                label: 'Invited suppliers',
                value: invitedSuppliers.toString() ?? '',
              },
            ],
          });
          break;

        case NegotiationType.CODE_OF_CONDUCT:
          resultMetrics.push({
            label: 'Code of conduct',
            value: NegotiationType.CODE_OF_CONDUCT,
            metrics: [
              {
                label: 'Accepted CoC',
                value: metrics.attributes.counts.acceptedCoc.toString(),
              },
              {
                label: 'Invited suppliers',
                value: invitedSuppliers.toString() ?? '',
              },
            ],
          });
          break;

        case NegotiationType.PROLONG:
          resultMetrics.push({
            label: 'Prolong',
            value: NegotiationType.PROLONG,
            metrics: [
              {
                label: 'Accepted prolong',
                value: metrics.attributes.counts.acceptedProlong.toString(),
              },
              {
                label: 'Invited suppliers',
                value: invitedSuppliers.toString() ?? '',
              },
            ],
          });
          break;

        case NegotiationType.STANDARD_AGREEMENT:
          resultMetrics.push({
            label: 'Standard agreement',
            value: NegotiationType.STANDARD_AGREEMENT,
            metrics: [
              {
                label: 'Accepted standard agreement',
                value:
                  metrics.attributes.counts.acceptedStandardAgreement.toString(),
              },
              {
                label: 'Invited suppliers',
                value: invitedSuppliers.toString() ?? '',
              },
            ],
          });
          break;

        case NegotiationType.SUPPLIER_QUESTIONNAIRE:
          resultMetrics.push({
            label: 'Supplier questionnaire',
            value: NegotiationType.SUPPLIER_QUESTIONNAIRE,
            metrics: [
              {
                label: 'Answered questionnaire',
                value:
                  metrics.attributes.counts.answeredQuestionnaire.toString(),
              },
              {
                label: 'Invited suppliers',
                value: invitedSuppliers.toString() ?? '',
              },
            ],
          });
          break;

        case NegotiationType.ISO:
          resultMetrics.push({
            label: 'ISO',
            value: NegotiationType.ISO,
            metrics: [
              {
                label: 'Answered ISO',
                value: metrics.attributes.counts.answeredIso.toString(),
              },
              {
                label: 'Invited suppliers',
                value: invitedSuppliers.toString() ?? '',
              },
            ],
          });
          break;

        case NegotiationType.SUPPLIER_REGISTRATION:
          resultMetrics.push({
            label: 'Supplier registration',
            value: NegotiationType.SUPPLIER_REGISTRATION,
            metrics: [
              {
                label: 'Invited suppliers',
                value: invitedSuppliers.toString() ?? '',
              },
            ],
          });
          break;
      }
    });

    return resultMetrics ?? [];
  }, [currency, metrics, type, addressedSpend, invitedSuppliers]);
};

import { useTranslation } from 'react-i18next';

import { negotiationNestedRoutes } from '@/modules/router/negotiation.routes';
import { Tab } from '@/modules/theme/components/Tabs/Tabs';

export const useNegotiationTabItems = () => {
  const { t } = useTranslation('pages/AnalysisAndOfferPage');

  const negotiationTabItems: Tab[] = [
    {
      link: negotiationNestedRoutes.config.overview.nestedPath,
      text: t('Overview'),
    },
    {
      link: negotiationNestedRoutes.config.suppliers.nestedPath,
      text: t('Suppliers'),
    },
  ];

  return negotiationTabItems;
};

import { ReactComponent as WifiIcon } from '@/assets/icons/wifiIcon.svg';
import { Card, CardContent } from '@/components/ui/card';
import InfiniteScroll from '@/components/ui/infinit-scroll';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';

import BoxHeaderTitle from './box-header-title';

export enum EventFeedItemSizes {
  Small = 'small',
  Large = 'large',
}

export interface EventFeedItem {
  supplier: string;
  date: Date;
  eventData: {
    label: string;
    value: string;
    size?: EventFeedItemSizes;
  }[];
}

interface EventSection {
  date: string;
  events: EventFeedItem[];
}

interface EventFeedProps {
  eventData: EventSection[];
  onLoadMore: () => void;
  hasMore: boolean;
  isLoading: boolean;
  className?: string;
}

const EventFeed = ({
  eventData,
  hasMore,
  isLoading,
  onLoadMore,
  className,
}: EventFeedProps) => {
  return (
    <Card className={`bg-white ${className ?? ''}`}>
      <BoxHeaderTitle title="Event feed" />

      <CardContent className="relative p-0 pb-6 mt-2">
        <ScrollArea className="h-full overflow-y-auto max-h-[835px] flex-1 px-10">
          <InfiniteScroll
            loadMore={onLoadMore}
            hasMore={hasMore}
            isLoading={isLoading}
          >
            <div id="scrollableDiv">
              {eventData.length === 0 && (
                <div className="flex flex-col items-center justify-center h-60 space-y-8 opacity-50">
                  <WifiIcon />
                  <span className="text-[#234B67] text-lg">
                    No answers found
                  </span>
                </div>
              )}
              {eventData.map((section, sectionIndex) => (
                <div key={sectionIndex} className="mt-10 first:mt-0">
                  <div className="mb-6">
                    <span className="font-semibold text-[#234b67] text-xs">
                      {section.date}
                    </span>
                  </div>

                  <div className="space-y-2">
                    {section.events.map((event, eventIndex) => (
                      <Card
                        key={eventIndex}
                        className="bg-[#f2f2f2] shadow-none"
                      >
                        <CardContent className="flex items-center flex-wrap py-2">
                          <div className="flex pl-8">
                            <div className="w-[200px]">
                              <div className="font-semibold text-[#234b67] text-base">
                                {event.supplier}
                              </div>
                              <div className="font-semibold text-[#4ba5b4] text-base">
                                {String(event.date.getHours()).padStart(2, '0')}
                                :
                                {String(event.date.getMinutes()).padStart(
                                  2,
                                  '0'
                                )}
                              </div>
                            </div>
                            <Separator
                              orientation="vertical"
                              className="h-auto bg-[#cccccc] ml-4 mr-8"
                            />
                          </div>

                          <div className="flex flex-1 items-start flex-wrap">
                            {event.eventData.map((event, index) => (
                              <div
                                key={index}
                                className={`min-w-[140px] px-1 ${
                                  event.size === EventFeedItemSizes.Large
                                    ? 'flex-2'
                                    : ''
                                }`}
                              >
                                <div className="font-semibold text-[#234b67] text-xs">
                                  {event.label}
                                </div>
                                <div className="text-[#234b67] text-xs">
                                  {event.value}
                                </div>
                              </div>
                            ))}
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </ScrollArea>
      </CardContent>
    </Card>
  );
};

export default EventFeed;

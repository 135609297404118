import { useMemo } from 'react';

import { ActiveNegotiationMetricsType } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { NegotiationType } from '@/modules/negotiation/types/negotiationTypes';

const getAcceptedSpendByNegotiationType = (
  metrics: ActiveNegotiationMetricsType,
  activeTab: string
) => {
  switch (activeTab as NegotiationType) {
    case NegotiationType.IMPROVED_PRICES:
      return metrics.attributes.spend.discount;
    case NegotiationType.LOCKED_PRICES:
      return metrics.attributes.spend.lockedPrice;
    case NegotiationType.EXTRA_PAYMENT_DAYS:
      return metrics.attributes.spend.acceptedExtraPaymentDays;
    case NegotiationType.CODE_OF_CONDUCT:
      return metrics.attributes.spend.acceptedCoc;
    case NegotiationType.STANDARD_AGREEMENT:
      return metrics.attributes.spend.acceptedStandardAgreement;
    case NegotiationType.SUPPLIER_QUESTIONNAIRE:
      return metrics.attributes.spend.answeredQuestionnaire;
    case NegotiationType.ISO:
      return metrics.attributes.spend.answeredIso;
    case NegotiationType.PROLONG:
      return metrics.attributes.spend.acceptedProlong;
    case NegotiationType.SUPPLIER_REGISTRATION:
      return metrics.attributes.spend.supplierRegistration;
    default:
      return 0;
  }
};

const getAcceptedSuppliersByNegotiationType = (
  metrics: ActiveNegotiationMetricsType,
  activeTab: string
) => {
  switch (activeTab as NegotiationType) {
    case NegotiationType.IMPROVED_PRICES:
      return metrics.attributes.counts.discount;
    case NegotiationType.LOCKED_PRICES:
      return metrics.attributes.counts.lockedPrice;
    case NegotiationType.EXTRA_PAYMENT_DAYS:
      return metrics.attributes.counts.acceptedExtraPaymentDays;
    case NegotiationType.CODE_OF_CONDUCT:
      return metrics.attributes.counts.acceptedCoc;
    case NegotiationType.STANDARD_AGREEMENT:
      return metrics.attributes.counts.acceptedStandardAgreement;
    case NegotiationType.SUPPLIER_QUESTIONNAIRE:
      return metrics.attributes.counts.answeredQuestionnaire;
    case NegotiationType.ISO:
      return metrics.attributes.counts.answeredIso;
    case NegotiationType.PROLONG:
      return metrics.attributes.counts.acceptedProlong;
    case NegotiationType.SUPPLIER_REGISTRATION:
      return metrics.attributes.counts.supplierRegistration;
    default:
      return 0;
  }
};

export const useNegotiationCompletedMetrics = (
  metrics?: ActiveNegotiationMetricsType,
  activeTab?: string
) => {
  const completedSpendMetrics = useMemo(() => {
    if (!metrics || !activeTab) {
      return undefined;
    }
    const answeredSpend =
      metrics.attributes.supplierStatesSpend.NEGOTIATION_COMPLETED;
    const acceptedSpend = getAcceptedSpendByNegotiationType(metrics, activeTab);
    const declinedSpend = answeredSpend - acceptedSpend;

    const label =
      (activeTab as NegotiationType) ===
        NegotiationType.SUPPLIER_REGISTRATION ||
      (activeTab as NegotiationType) ===
        NegotiationType.SUPPLIER_QUESTIONNAIRE ||
      (activeTab as NegotiationType) === NegotiationType.ISO
        ? 'Answered'
        : 'Accepted';

    return [
      {
        label,
        value: acceptedSpend,
        percentage: (acceptedSpend / answeredSpend) * 100,
        color: '#DC840A',
      },
      {
        label: `Not ${label.toLowerCase()}`,
        value: declinedSpend,
        percentage: (declinedSpend / answeredSpend) * 100,
        color: '#1A364C',
      },
    ];
  }, [activeTab, metrics]);

  const completedSuppliersMetrics = useMemo(() => {
    if (!metrics || !activeTab) {
      return undefined;
    }
    const acceptedSuppliers = getAcceptedSuppliersByNegotiationType(
      metrics,
      activeTab
    );

    const answeredSuppliers =
      metrics.attributes.supplierStatesCount.NEGOTIATION_COMPLETED;
    const declinedSuppliers = answeredSuppliers - acceptedSuppliers;

    const label =
      (activeTab as NegotiationType) ===
        NegotiationType.SUPPLIER_REGISTRATION ||
      (activeTab as NegotiationType) ===
        NegotiationType.SUPPLIER_QUESTIONNAIRE ||
      (activeTab as NegotiationType) === NegotiationType.ISO
        ? 'Answered'
        : 'Accepted';

    return [
      {
        label,
        value: acceptedSuppliers,
        percentage: (acceptedSuppliers / answeredSuppliers) * 100,
        color: '#4BA5B4',
      },
      {
        label: `Not ${label.toLowerCase()}`,
        value: declinedSuppliers,
        percentage: (declinedSuppliers / answeredSuppliers) * 100,
        color: '#1A364C',
      },
    ];
  }, [metrics, activeTab]);

  return {
    completedSpendMetrics,
    completedSuppliersMetrics,
  };
};

import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { AuthenticatedLayout } from '@/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { launchedNegotiationStateFilters } from '@/modules/negotiation/utils/consts';
import { notify } from '@/modules/notify/notify';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { Box } from '@/modules/theme/components/Box';
import { PageHeader } from '@/modules/theme/components/PageHeader/PageHeader';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

import { useGetNegotiationsQueryV2 } from '../AnalysisAndOfferPage/tabs/SuggestedNegotiationsPage/hooks/useGetNegotiationsV2Query';

import { NegotiationAfterStartCard } from './components/NegotiationCards/NegotiationCard/NegotiationAfterStartCard';
import { NegotiationBeforeStartCard } from './components/NegotiationCards/NegotiationCard/NegotiationBeforeStartCard';
import { NegotiationStateFilter } from './components/NegotiationStateFilter/NegotiationStateFilter';
import { ContentWrapperStyled, LogoStyled } from './negotiations.styled';
import { getNegotiationStateCounts } from './utils/get-negotiation-state-counts';
import { getNegotiationDetailsPath } from './utils/getNegotiationDetailsPath';

export const NegotiationsPage = () => {
  const { t } = useTranslation('pages/NegotiationsPage');

  const { queryParams, queryParamsError } =
    onboardedRoutes.useGetQueryParams('negotiations');

  const setSearchParams = onboardedRoutes.useSetQueryParams('negotiations');

  const setNegotiationState = (negotiationState: NegotiationStateV2[]) => {
    setSearchParams({
      state: negotiationState.length > 0 ? negotiationState : undefined,
    });
  };

  const {
    customer: { id: customerId },
  } = useSessionContext();

  useEffect(() => {
    if (queryParamsError) {
      notify.alert.error(t('Invalid negotiation states'));
    }
  }, [queryParamsError, t]);

  const chosenNegotiationStates = useMemo(
    () => queryParams?.state || [],
    [queryParams?.state]
  );

  const toggleState = (pickedState: NegotiationStateV2) => {
    const wasPicked = chosenNegotiationStates.includes(pickedState);

    const updatedNegotiationState = wasPicked
      ? chosenNegotiationStates.filter((filter) => filter !== pickedState)
      : [...chosenNegotiationStates, pickedState];

    setNegotiationState(updatedNegotiationState);
  };

  const { data, isPending, isPlaceholderData } = useGetNegotiationsQueryV2(
    {
      page: 1,
      limit: 100,
      customerId,
      includeNegotiationMetrics: true,
      states: chosenNegotiationStates.length
        ? chosenNegotiationStates
        : launchedNegotiationStateFilters,
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000,
      refetchInterval: 60_000,
    }
  );

  const negotiationStateCounts = useMemo(
    () => getNegotiationStateCounts(data?.data.items),
    [data]
  );

  if (queryParamsError) {
    return <Navigate to={onboardedRoutes.buildUrl('negotiations')} />;
  }

  return (
    <AuthenticatedLayout>
      <PageMeta title={t('Dashboard')} description={t('Dashboard')} />
      <PageHeader text={t('Dashboard')} />
      <ContentWrapperStyled>
        <LogoStyled />
        <NegotiationStateFilter
          negotiationStateCounts={negotiationStateCounts}
          isLoading={isPending}
          onStateToggle={toggleState}
          chosenStates={chosenNegotiationStates}
        />
        {isPending || isPlaceholderData ? (
          <SkeletonLoader lines={3} height={260} />
        ) : (
          data && (
            <Box mt={6}>
              {data.data.items
                .filter(
                  (item) =>
                    !(
                      (item.attributes.state === NegotiationStateV2.DEMO ||
                        item.attributes.state ===
                          NegotiationStateV2.SUGGESTED) &&
                      (item.attributes.suggestedPeriod === undefined ||
                        item.attributes.suggestedPeriod < 0)
                    )
                )
                .map((negotiation) => {
                  const hasNegotiationStarted =
                    negotiation.attributes.state ===
                      NegotiationStateV2.COMPLETED ||
                    negotiation.attributes.state ===
                      NegotiationStateV2.IN_PROGRESS;
                  return hasNegotiationStarted ? (
                    <NegotiationAfterStartCard
                      negotiation={negotiation}
                      link={getNegotiationDetailsPath(negotiation)}
                      key={negotiation.id}
                    />
                  ) : (
                    <NegotiationBeforeStartCard
                      negotiation={negotiation}
                      link={getNegotiationDetailsPath(negotiation)}
                      key={negotiation.id}
                    />
                  );
                })}
            </Box>
          )
        )}
      </ContentWrapperStyled>
    </AuthenticatedLayout>
  );
};

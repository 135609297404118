import * as sup from 'superstruct';

import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';

import { NegotiationStateV2 } from '../../types/negotiationTypes';

export type GetNegotiationsSuppliersDashboardMetricsQueryParamsV2 = {
  customerId: string;
  after?: string;
  negotiationState: NegotiationStateV2;
  before?: string;
};

export const getNegotiationsSuppliersDashboardMetricsQueryV2 = (
  jsonApi: JsonApiV2Client,
  queryParams: GetNegotiationsSuppliersDashboardMetricsQueryParamsV2
) => {
  return async () => {
    const data = await jsonApi.get({
      path: `/v2/negotiation-metrics/dashboard/suppliers`,
      queryParams,
      responseStruct: getNegotiationsSuppliersDashboardMetricsResponseStructV2,
    });
    return getNegotiationsSuppliersDashboardMetricsResponseStructV2.mask(data);
  };
};

export type GetNegotiationsSuppliersDashboardMetricsResponseTypeV2 = sup.Infer<
  typeof getNegotiationsSuppliersDashboardMetricsResponseStructV2
>;

const getNegotiationsSuppliersDashboardMetricsResponseStructV2 = sup.object({
  data: sup.object({
    negotiationCount: sup.number(),
    supplierCount: sup.number(),
    uniqueSupplierCount: sup.number(),
    uniqueSuppliersActive: sup.number(),
    uniqueSuppliersAnswered: sup.number(),
    uniqueSuppliersInvited: sup.number(),
    uniqueSuppliersActiveSpend: sup.number(),
    uniqueSuppliersAnsweredSpend: sup.number(),
    uniqueSuppliersInvitedSpend: sup.number(),
  }),
});

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationResourceTypeV2 } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { NegotiationMetric } from '@/modules/negotiation/components/NegotiationMetric';
import { negotiationMetrics } from '@/modules/negotiation/metrics/consts';
import { getNegotiationCardMetricsV2 } from '@/pages/negotiations/components/NegotiationCards/NegotiationCard/getNegotiationCardMetricsV2';

type CompletedNegotiationMetricsProps = {
  negotiation: NegotiationResourceTypeV2;
};

export const CompletedNegotiationMetrics = ({
  negotiation,
}: CompletedNegotiationMetricsProps) => {
  const { t } = useTranslation('pages/CompletedNegotiationPage');
  const { customer } = useSessionContext();

  const metrics = negotiation.attributes.negotiationMetrics;

  const metricsToDisplay = getNegotiationCardMetricsV2(negotiation);

  if (!metrics) {
    return null;
  }

  const addressedSpend = (
    metrics.attributes.supplierStatesSpend.OPENED_EMAIL +
    metrics.attributes.supplierStatesSpend.VISITED +
    metrics.attributes.supplierStatesSpend.NEGOTIATION_STARTED +
    metrics.attributes.supplierStatesSpend.NEGOTIATION_COMPLETED
  ).toFixed(2);

  return (
    <CompletedNegotiationMetricsWrapperStyled>
      <FinancialMetricsWrapperStyled>
        {metricsToDisplay.has(negotiationMetrics.negotiatedSavings) && (
          <NegotiationMetric
            heading={t('Negotiated savings ({{currency}})', {
              currency: customer.currency,
            })}
            data={metrics.attributes.improvedPricesValue || 0}
          />
        )}

        {metricsToDisplay.has(negotiationMetrics.negotiatedCostAvoidance) && (
          <NegotiationMetric
            heading={t('Negotiated cost avoidance ({{currency}})', {
              currency: customer.currency,
            })}
            data={metrics.attributes.lockedPricesValue || 0}
          />
        )}

        {metricsToDisplay.has(negotiationMetrics.negotiatedSpend) && (
          <NegotiationMetric
            heading={t('Negotiated spend ({{currency}})', {
              currency: customer.currency,
            })}
            data={addressedSpend || 0}
          />
        )}
      </FinancialMetricsWrapperStyled>
      <CounterMetricsWrapper>
        {metricsToDisplay.has(negotiationMetrics.discountCount) && (
          <NegotiationMetric
            heading={t('Discounts')}
            data={metrics.attributes.counts.discount}
            variant="counter"
          />
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.lockPrice
        ) && (
          <NegotiationMetric
            heading={t('Locked prices')}
            data={metrics.attributes.counts.lockedPrice}
            variant="counter"
          />
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.signCodeOfConduct
        ) && (
          <NegotiationMetric
            heading={t('Accepted Code of Conduct')}
            data={metrics.attributes.counts.acceptedCoc}
            variant="counter"
          />
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.answerSustainabilityQuestion
        ) && (
          <NegotiationMetric
            heading={t('Answered questionnaires')}
            data={metrics.attributes.counts.answeredQuestionnaire}
            variant="counter"
          />
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.extendInvoiceDueDate
        ) && (
          <NegotiationMetric
            heading={t('Accepted payment days')}
            data={metrics.attributes.counts.acceptedExtraPaymentDays}
            variant="counter"
          />
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.extendContractPeriod
        ) && (
          <NegotiationMetric
            heading={t('Accepted prolongation')}
            data={metrics.attributes.counts.acceptedExtraPaymentDays}
            variant="counter"
          />
        )}
      </CounterMetricsWrapper>
    </CompletedNegotiationMetricsWrapperStyled>
  );
};

const CompletedNegotiationMetricsWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    flex: 1,
    display: 'flex',
    gap: spacing(11),
  })
);

const CounterMetricsWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 0.3fr))',
  columnGap: spacing(2),
  gridAutoRows: 94,
  flex: 1,
}));

const FinancialMetricsWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    minWidth: 240,
    '&:empty': {
      marginRight: spacing(-11),
      minWidth: 0,
    },
  })
);

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApiV2 } from '@/modules/api/hooks/useJsonApiV2';
import { queryKeys } from '@/modules/api/queryKeys';
import {
  GetNegotiationsDashboardMetricsQueryParamsV2,
  getNegotiationsDashboardMetricsQueryV2,
} from '@/modules/negotiation/api/queries/get-negotiation-dashboard-metrics';
import { GetNegotiationsQueryOptions } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceConsts';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export const useGetNegotiationsDashboardMetrics = (
  params: GetNegotiationsDashboardMetricsQueryParamsV2,
  options?: GetNegotiationsQueryOptions
) => {
  const jsonApi = useJsonApiV2();
  const { t } = useTranslation(
    'apiMessages/useGetNegotiationsDashboardMetricsQuery'
  );
  return useQuery({
    queryKey: [
      queryKeys.negotiations.getNegotiationsDashboardMetrics,
      params.customerId,
      params.after,
      params.before,
    ],
    queryFn: getNegotiationsDashboardMetricsQueryV2(jsonApi, params),
    ...options,
    select(data) {
      return data.data;
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to view negotiations"),
        },
      },
    }),
  });
};

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { AuthenticatedLayout } from '@/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import {
  ActiveNegotiationMetricsType,
  NegotiationResourceTypeV2,
} from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { launchedNegotiationStateFilters } from '@/modules/negotiation/utils/consts';
import { notify } from '@/modules/notify/notify';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { Box } from '@/modules/theme/components/Box';
import { PageHeader } from '@/modules/theme/components/PageHeader/PageHeader';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

import { useGetNegotiationsQueryV2 } from '../AnalysisAndOfferPage/tabs/SuggestedNegotiationsPage/hooks/useGetNegotiationsV2Query';
import { NegotiationAfterStartCard } from '../negotiations/components/NegotiationCards/NegotiationCard/NegotiationAfterStartCard';
import { NegotiationBeforeStartCard } from '../negotiations/components/NegotiationCards/NegotiationCard/NegotiationBeforeStartCard';
import { NegotiationStateFilter } from '../negotiations/components/NegotiationStateFilter/NegotiationStateFilter';
import {
  ContentWrapperStyled,
  LogoStyled,
} from '../negotiations/negotiations.styled';
import { getNegotiationDetailsPath } from '../negotiations/utils/getNegotiationDetailsPath';

import { CustomerMetricsPanel } from './components/CustomerMetricsPanel/CustomerMetricsPanel';
import { calculateNegotiationsMetrics } from './utils/sum-negotiations-metrics';

const getNegotiationStateCounts = (
  negotiations?: NegotiationResourceTypeV2[]
): Record<NegotiationStateV2, number> | undefined => {
  if (!negotiations) {
    return undefined;
  }
  return negotiations
    .filter(
      (item) =>
        !(
          (item.attributes.state === NegotiationStateV2.DEMO ||
            item.attributes.state === NegotiationStateV2.SUGGESTED) &&
          (item.attributes.suggestedPeriod === undefined ||
            item.attributes.suggestedPeriod < 0)
        )
    )
    .reduce(
      (acc: Record<NegotiationStateV2, number>, negotiation) => {
        acc[negotiation.attributes.state as NegotiationStateV2] =
          (acc[negotiation.attributes.state as NegotiationStateV2] || 0) + 1;
        return acc;
      },
      {} as Record<NegotiationStateV2, number>
    );
};

export const DashboardPageOld = () => {
  const { t } = useTranslation('pages/DashboardPage');
  const { queryParams, queryParamsError } =
    onboardedRoutes.useGetQueryParams('dashboardOld');
  const {
    customer: { id: customerId },
  } = useSessionContext();

  const chosenNegotiationStates = queryParams?.state ?? [];

  const setSearchParams = onboardedRoutes.useSetQueryParams('dashboard');

  const setNegotiationState = (negotiationState: NegotiationStateV2[]) => {
    setSearchParams({
      state: negotiationState.length > 0 ? negotiationState : undefined,
    });
  };

  useEffect(() => {
    if (queryParamsError) {
      notify.alert.error(t('Invalid negotiation states'));
    }
  }, [queryParamsError, t]);

  const [metrics, setMetrics] = useState<
    ActiveNegotiationMetricsType | undefined
  >(undefined);

  const toggleState = (pickedState: NegotiationStateV2) => {
    const wasPicked = chosenNegotiationStates.includes(pickedState);

    const updatedNegotiationState = wasPicked
      ? chosenNegotiationStates.filter((filter) => filter !== pickedState)
      : [...chosenNegotiationStates, pickedState];

    setNegotiationState(updatedNegotiationState);
  };

  const { data, isPending, isPlaceholderData } = useGetNegotiationsQueryV2(
    {
      page: 1,
      limit: 100,
      customerId,
      includeNegotiationMetrics: true,
      states: chosenNegotiationStates.length
        ? chosenNegotiationStates
        : launchedNegotiationStateFilters,
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000,
      refetchInterval: 60_000,
    }
  );

  const negotiationStateCounts = getNegotiationStateCounts(data?.data.items);

  useEffect(() => {
    if (data && !isPending) {
      const negotiationMetrics = data.data.items
        .filter(
          (item) =>
            !(
              (item.attributes.state === NegotiationStateV2.DEMO ||
                item.attributes.state === NegotiationStateV2.SUGGESTED) &&
              (item.attributes.suggestedPeriod === undefined ||
                item.attributes.suggestedPeriod < 0)
            )
        )
        .map((item) => item.attributes.negotiationMetrics)
        .filter((item) => item !== undefined);

      const metrics = calculateNegotiationsMetrics(
        negotiationMetrics as ActiveNegotiationMetricsType[]
      );

      setMetrics(metrics);
    }
  }, [data, isPending]);

  if (queryParamsError) {
    return <Navigate to={onboardedRoutes.buildUrl('dashboard')} />;
  }

  return (
    <AuthenticatedLayout>
      <PageMeta title={t('Dashboard')} description={t('Dashboard')} />
      <PageHeader text={t('Dashboard')} />
      <ContentWrapperStyled>
        <LogoStyled />
        <CustomerMetricsPanel metrics={metrics} isLoading={isPending} />
        <NegotiationStateFilter
          negotiationStateCounts={negotiationStateCounts}
          isLoading={isPending}
          onStateToggle={toggleState}
          chosenStates={chosenNegotiationStates}
        />
        {isPending || isPlaceholderData ? (
          <SkeletonLoader lines={3} height={260} />
        ) : (
          data && (
            <Box mt={6}>
              {data.data.items
                .filter(
                  (item) =>
                    !(
                      (item.attributes.state === NegotiationStateV2.DEMO ||
                        item.attributes.state ===
                          NegotiationStateV2.SUGGESTED) &&
                      (item.attributes.suggestedPeriod === undefined ||
                        item.attributes.suggestedPeriod < 0)
                    )
                )
                .map((negotiation) => {
                  const hasNegotiationStarted =
                    negotiation.attributes.state ===
                      NegotiationStateV2.COMPLETED ||
                    negotiation.attributes.state ===
                      NegotiationStateV2.IN_PROGRESS;
                  return hasNegotiationStarted ? (
                    <NegotiationAfterStartCard
                      negotiation={negotiation}
                      link={getNegotiationDetailsPath(negotiation, true)}
                      key={negotiation.id}
                    />
                  ) : (
                    <NegotiationBeforeStartCard
                      negotiation={negotiation}
                      link={getNegotiationDetailsPath(negotiation, true)}
                      key={negotiation.id}
                    />
                  );
                })}
            </Box>
          )
        )}
      </ContentWrapperStyled>
    </AuthenticatedLayout>
  );
};

import * as sup from 'superstruct';

import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';

import { NegotiationStateV2 } from '../../types/negotiationTypes';

export type GetNegotiationsDashboardMetricsQueryParamsV2 = {
  customerId: string;
  after?: string;
  before?: string;
  negotiationState?: NegotiationStateV2;
};

export const getNegotiationsDashboardMetricsQueryV2 = (
  jsonApi: JsonApiV2Client,
  queryParams: GetNegotiationsDashboardMetricsQueryParamsV2
) => {
  return async () => {
    const data = await jsonApi.get({
      path: `/v2/negotiation-metrics/dashboard`,
      queryParams,
      responseStruct: getNegotiationsDashboardMetricsResponseStructV2,
    });
    return getNegotiationsDashboardMetricsResponseStructV2.mask(data);
  };
};

export type GetNegotiationsDashboardMetricsResponseTypeV2 = sup.Infer<
  typeof getNegotiationsDashboardMetricsResponseStructV2
>;

const getNegotiationsDashboardMetricsResponseStructV2 = sup.object({
  data: sup.object({
    spend: sup.object({
      totalSpend: sup.number(),
      activeSpend: sup.number(),
      completedSpend: sup.number(),
      acceptedSpend: sup.number(),
    }),
    value: sup.object({
      generatedValue: sup.number(),
      improvedPricesValue: sup.number(),
      lockedPricesValue: sup.number(),
      extraPaymentDaysValue: sup.number(),
    }),
    suppliers: sup.object({
      suppliersInvited: sup.number(),
      suppliersActive: sup.number(),
      suppliersResponded: sup.number(),
      suppliersAccepted: sup.number(),
    }),
    counts: sup.object({
      answers: sup.number(),
      discounts: sup.number(),
      lockedPrices: sup.number(),
      acceptedExtraPaymentDays: sup.number(),
      acceptedProlong: sup.number(),
      acceptedCoc: sup.number(),
      acceptedStandardAgreement: sup.number(),
      answeredSupplierQuestionnaire: sup.number(),
      answeredIso: sup.number(),
      suppliersOnboardingCompleted: sup.number(),
    }),
    improvedPrices: sup.object({
      averageDiscount: sup.optional(sup.number()),
      improvedPricesInvited: sup.number(),
      improvedPricesAddressed: sup.number(),
      improvedPricesAnswers: sup.number(),
      improvedPricesAccepted: sup.number(),
      improvedPricesActiveSpend: sup.number(),
    }),
    lockedPrices: sup.object({
      lockedPricesInvited: sup.number(),
      lockedPricesAddressed: sup.number(),
      lockedPricesAnswers: sup.number(),
      lockedPricesAccepted: sup.number(),
      lockedPricesActiveSpend: sup.number(),
    }),
    extraPaymentDays: sup.object({
      workingCapitalImprovement: sup.number(),
      extraPaymentDaysInvited: sup.number(),
      extraPaymentDaysAddressed: sup.number(),
      extraPaymentDaysAnswers: sup.number(),
      extraPaymentDaysAccepted: sup.number(),
      extraPaymentDaysActiveSpend: sup.number(),
    }),
    coc: sup.object({
      cocInvited: sup.number(),
      cocAddressed: sup.number(),
      cocAnswers: sup.number(),
      cocAccepted: sup.number(),
    }),
    standardAgreement: sup.object({
      standardAgreementInvited: sup.number(),
      standardAgreementAddressed: sup.number(),
      standardAgreementAnswers: sup.number(),
      standardAgreementAccepted: sup.number(),
    }),
    questionnaire: sup.object({
      questionnaireInvited: sup.number(),
      questionnaireAddressed: sup.number(),
      questionnaireAnswers: sup.number(),
      questionnaireAccepted: sup.number(),
    }),
  }),
});
